.img__polaroid {
    width: 10%;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 0 auto;
    height: 50%;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    object-fit: contain;
  }
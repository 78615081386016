.social__feed {
    flex: 1;
    margin: 0 auto;
}

.social__container {
    padding: 15px;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    
}

.social__input {
    border: 1px solid lightgray;
    border-radius: 30px;
    display: flex;
    padding: 10px;
    color:  #282c34;
    padding-left: 15px;
}

.social__input > form {
    display: flex;
    width: 100%;
}

.social__input > form > input {
    border: none;
    flex: 1;
    margin-left: 10px;
    outline-width: 0;
    font-weight: 600;
}

.social__input > form > button {
    display: none;
}

.social__options {
    display: flex;
    justify-content: space-evenly;

}

.dropdown__menu{
    padding: 10px;
    margin-top: 25px;

}
.content__input {
    border: none;
    flex: 1;
    margin-left: 10px;
    outline-width: 0;
    font-weight: 600;
}
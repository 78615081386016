.loginForm {
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    padding-left: 20px;
    padding-right: 20px;
    color : white;
}

.inputField {
    padding-top: 16px;
}

.input {
    box-sizing: border-box;
    outline: none;
    border: solid 2px #1189de;
    border-radius: 4px;
    color: black;
    width: 100%;
    padding: 12px;
    font-size: 14px;
    background: rgba(255,255,255,1);
}

.buttonContainer {
    padding-top: 16px;
}

.submitBtn {
    width : 100%;
    min-width: 280px;
    color : #565656;
    padding: 12px;
    font-size: 14px;
    font-weight: bold;
    border: solid 2px #1189de;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
}

.App {
    text-align: center;
    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
    height: 100vh;
  }
  
  

